import React, { useState } from "react";
import ButtonComponent from "../../components/Buttons/button";
import { useLocation, useNavigate } from "react-router-dom";
import icons from "../../utils/icons";
import { PasswordInput } from "../../components/InputFields/PasswordInput";
import constants from "../../utils/constants";
import api from "../../services/api";
import { showToast } from "../../redux/actions/toastAction";
import { useDispatch } from "react-redux";
import {
  atLeastOneSpecialCharacter,
  atLeastOneCapitalCase,
  atLeastOneLowerCase,
  atLeastOneNumber,
} from "../../utils/regex/regex";
import { PasswordInputWithStrength } from "../../components/InputFields/PasswordInputWithStrength";
import {
  hideLoaderAction,
  showLoaderAction,
} from "../../redux/actions/loaderAction";
import { passwordValidator } from "../../utils/validations";

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { email, otp } = location.state;

  const [showResetComplete, setShowResetComplete] = useState(false);
  const dispatch = useDispatch();
  const [isError, setIsError] = useState({});
  const [data, setData] = useState({
    email: email,
    password: "",
    confirmPassword: "",
    otp: otp,
    otpType: 2,
  });

  const navigateToLogin = () => {
    navigate("/sign-in");
  };

  const [passwordValidations, setPasswordValidations] = useState({
    atLeast8Characters: false,
    atLeastOneLowerCase: false,
    atLeastOneCapitalCase: false,
    atLeastOneNumber: false,
    atLeastOneSpecialCharacter: false,
  });

  const checkPasswordValidations = (value) => {
    return setPasswordValidations({
      atLeast8Characters: value.length >= 8,
      atLeastOneLowerCase: atLeastOneLowerCase.test(value),
      atLeastOneCapitalCase: atLeastOneCapitalCase.test(value),
      atLeastOneNumber: atLeastOneNumber.test(value),
      atLeastOneSpecialCharacter: atLeastOneSpecialCharacter.test(value),
    });
  };

  const [confirmPasswordValidations, setConfirmPasswordValidations] = useState({
    atLeast8Characters: false,
    atLeastOneLowerCase: false,
    atLeastOneCapitalCase: false,
    atLeastOneNumber: false,
    atLeastOneSpecialCharacter: false,
  });

  const checkConfirmPasswordValidations = (value) => {
    return setConfirmPasswordValidations({
      atLeast8Characters: value.length >= 8,
      atLeastOneLowerCase: atLeastOneLowerCase.test(value),
      atLeastOneCapitalCase: atLeastOneCapitalCase.test(value),
      atLeastOneNumber: atLeastOneNumber.test(value),
      atLeastOneSpecialCharacter: atLeastOneSpecialCharacter.test(value),
    });
  };

  const handleChange = (name) => (event) => {
    const stringValidatorValues = Object.values(passwordValidations);

    if (!stringValidatorValues.every((element) => element === true)) {
      errors.password = "Password must match all requirements";
    }
    setData({ ...data, [name]: event.target.value });
  };

  let errors = {
    password: false,
    confirmPassword: false,
  };

  const CreateNPasswordAPI = async () => {
    dispatch(showLoaderAction());
    const res = await api("post", constants.endPoints.ResetPassword, data);
    if (res.success) {
      setShowResetComplete(true);
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

  const handleSubmit = async () => {
    let formErrors = await passwordValidator(
      data,
      passwordValidations,
      confirmPasswordValidations
    );
    console.log(formErrors);
    setIsError({ ...formErrors });
    if (!formErrors) CreateNPasswordAPI();
  };

  const CreatePassword = () => {
    const { Logo } = icons();
    return (
      <>
        <div className=" ">
          <div className="grid p-0 m-0">
            <div className="md:col-6 bgsign-image">
              <div className="flex flex-column align-items-center px-4 lg:px-0 w-12 lg:w-9 ">
                <div className="">
                  <h3 className="lg:text-6xl text-30 text-primary font-medium lg:line-height-3 m-0">
                    Exchange the Crypto in Mintutes
                  </h3>
                  <p className="text-sm md:text-base lg:py-2 w-12 lg:w-9">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:col-5 col-12 p-0 lg:mt-8 mb-8 m-auto  flex flex-column justify-content-center  ">
              <div className="surface-500 border-round-2xl lg:px-6 px-4 py-6">
                <div className="text-center pb-3">
                  <small style={{ width: "60px", height: "27px" }}>
                    <Logo />
                  </small>
                </div>
                <h3 className="lg:text-3xl text-26 font-medium text-primary my-2">
                  Create New Password
                </h3>
                <div className="">
                  <PasswordInputWithStrength
                    type="password"
                    onChange={handleChange("password")}
                    placeholder="Enter New Password"
                    value={data.password}
                    required
                    icon="pi-eye"
                    label="New Password"
                    passwordValidations={passwordValidations}
                    checkPasswordValidations={checkPasswordValidations}
                  ></PasswordInputWithStrength>
                  {isError?.password && (
                    <p className="text-red-600 text-xs mt-1">
                      {isError?.password}
                    </p>
                  )}
                </div>
                <div className="my-3">
                  <PasswordInputWithStrength
                    type="password"
                    onChange={handleChange("confirmPassword")}
                    placeholder="Enter Confirm Password"
                    value={data.confirmPassword}
                    required
                    icon="pi-eye"
                    label="Confirm New Password"
                    passwordValidations={confirmPasswordValidations}
                    checkPasswordValidations={checkConfirmPasswordValidations}
                  ></PasswordInputWithStrength>
                  {isError?.confirmPassword && (
                    <p className="text-red-600 text-xs mt-1">
                      {isError?.confirmPassword}
                    </p>
                  )}
                </div>

                <div className="col-12   mx-0 px-0">
                  <ButtonComponent
                    onClick={handleSubmit}
                    label="Continue"
                    className="col-12  "
                  ></ButtonComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const ResetComplete = () => {
    const { ResetCompleteImg } = icons();
    return (
      <>
        <div className="grid p-0 m-0">
          <div className="md:col-6 bgsign-image">
            <div className="flex flex-column align-items-center px-4 lg:px-0">
              <div className="">
                <h3 className="lg:text-6xl text-30 text-primary font-medium lg:line-height-3 m-0">
                  Exchange the Crypto <br />
                  in Mintutes
                </h3>
                <p className="text-normal lg:py-2 ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do
                  <br /> eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. <br /> Ut enim ad minim veniam, quis nostrud
                  exercitation
                </p>
              </div>
            </div>
          </div>
          <div className="lg:col-5 col-12 p-0 lg:mt-8 mb-8  m-auto flex flex-column justify-content-center text-center  ">
            <div className="surface-500 border-round-2xl lg:px-6 px-4 py-6 ">
              <div className="text-center  pb-3">
                <small style={{ width: "60px", height: "27px" }}>
                  <ResetCompleteImg />
                </small>
              </div>
              <h3 className="lg:text-3xl text-2xl font-medium text-primary my-2">
                Password Reset Completed
              </h3>
              <p className="text-center">
                Now, you can log in to your account with <br />
                the new password.
              </p>
              <div className="col-12   mx-0 px-0">
                <ButtonComponent
                  onClick={navigateToLogin}
                  label="GOT IT"
                  className="col-12  "
                ></ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return <>{showResetComplete ? ResetComplete() : CreatePassword()}</>;
};

export default CreateNewPassword;
