import React from "react";
import BlurEffect from "../../components/BlurEffect";
import tronImage from "../../assets/images/tron_image.png";
import etherumImage from "../../assets/images/etherum_image.png";
import Footer from "../../layout/Footer";

export default function About() {
  return (
    <>
      <div className="about-hero z-2">
        <div className="container ">
          <h1 className="text-40 font-semibold text-primary">About Us</h1>
        </div>
        {/* hello */}
      </div>
      <div className="container relative z-1 pt-8">
        <div className="flex justify-content-center">
          {/* <h3 className="text-primary mt-0">About Us</h3> */}
        </div>
        <p className="text-center">
          Welcome to TINBOX (Tinbox Transfers Ltd.), where trust meets
          efficiency in the world of currency exchange. Born out of a deep
          understanding of P2P platforms, we've evolved beyond being active
          merchants to become your trusted ally in the realm of stablecoin
          transactions. No more alerts from your financial institution of fraud
          concerns.
        </p>
        <p className="text-center">
          Your concerns and experiences with scams and suspicious traders on P2P
          platforms have resonated with us, motivating TINBOX to step up and
          provide a safe, simple, and secure channel for buying and selling
          stablecoins at competitive rates.
        </p>
        <div className="flex justify-content-center">
          <h3 className="text-primary">OUR ACTIONS</h3>
        </div>
        <p className="text-center">
          Our dedication to your safety goes beyond mere words. Tinbox Transfers
          Ltd. is proud to announce its registration with FINTRAC as a Money
          Services Business (MSB) under the registration number #M23484249. This
          move solidifies our role in detecting, preventing, and deterring money
          laundering, terrorist financing, and other financial crimes.
        </p>
        <p className="text-center">
          We believe that an informed customer is an empowered one. Before
          delving into any cryptocurrency investment or purchase, we encourage
          you to visit the website by Canadian Department of Financial
          Protection & Innovation (DFPI) for a comprehensive list of known
          crypto-related scams. This extra layer of caution will safeguard your
          hard-earned money and shield you from falling victim to crypto scams.
        </p>
        <div className="flex align-items-center justify-content-center">
          <a href="https://dfpi.ca.gov/crypto-scams/">
            <span className="text-white">
              https://dfpi.ca.gov/crypto-scams/
            </span>
          </a>
        </div>
        <div className="flex align-items-center justify-content-center mt-4 pb-7">
          At TINBOX, we don't just exchange currencies; we build bridges of
          trust. <br />
          <br />
          Join us on this journey towards a secure and seamless exchange
          experience.
        </div>
      </div>
      {/* <div className="container relative z-1 pt-8">
        <p className="font-medium">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of
          the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown
          printer took a galley of type and scrambled it to make a type specimen
          book. It has survived not only five centuries, but also the leap into
          electronic typesetting, remaining essentially unchanged. It was
          popularised in the 1960s with the release of Letraset sheets
          containing Lorem Ipsum passages, and more recently with desktop
          publishing software like Aldus PageMaker including versions of Lorem
          Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of
          the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown
          printer took a galley of type and scrambled it to make a type specimen
          book. It has survived not only five centuries, but also the leap into
          electronic typesetting, remaining essentially unchanged.{" "}
        </p>
        <BlurEffect right="-5%" top="-20%" />
      </div>
      <div className="container py-5">
        <div className="flex flex-wrap">
          <div className="col-12 md:col-5">
            <img src={etherumImage} alt="etherum" />
          </div>
          <div className="col-12 md:col-7">
            <p className="font-medium md:p-5">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
        </div>
      </div>
      <div className="container pt-5 pb-8 ">
        <div className="flex flex-wrap">
          <div className="col-12 md:col-7">
            <p className="font-medium md:p-5">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
          <div className="col-12 md:col-5">
            <img src={tronImage} alt="tron" />
          </div>
        </div>
      </div> */}
      <div className="container-fluid bg-secondary">
        <Footer />
      </div>
    </>
  );
}
