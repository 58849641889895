import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import { CustomInput } from "../../components/InputFields/CustomInput";
import icons from "../../utils/icons";
import { ProgressBar } from "primereact/progressbar";
import constants from "../../utils/constants";
import api from "../../services/api";
import { showToast } from "../../redux/actions/toastAction";
import {
  atLeastOneSpecialCharacter,
  atLeastOneCapitalCase,
  atLeastOneLowerCase,
  atLeastOneNumber,
  validEmail,
} from "../../utils/regex/regex";
import { useDispatch } from "react-redux";
import VerificationCode from "./VerificationCode";
import { isAuthenticated } from "../../services/authService";
import { PasswordInputWithStrength } from "../../components/InputFields/PasswordInputWithStrength";
import { passwordValidator } from "../../utils/validations";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const SignUp = () => {
  const { EmailIcon, Logo, GoogleIcon } = icons();
  const dispatch = useDispatch();
  const [showVerificationPage, setShowVerificationPage] = useState();
  const [showEmail, setShowEmail] = useState();
  const [ShowUserProfile, setShowUserProfile] = useState();
  const navigate = useNavigate();

  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordValidations, setPasswordValidations] = useState({
    atLeast8Characters: false,
    atLeastOneLowerCase: false,
    atLeastOneUpperCase: false,
    atLeastOneNumber: false,
    atLeastOneSpecialCharacter: false,
  });

  const checkPasswordValidations = (value) => {
    return setPasswordValidations({
      atLeast8Characters: value.length >= 8,
      atLeastOneLowerCase: atLeastOneLowerCase.test(value),
      atLeastOneUpperCase: atLeastOneCapitalCase.test(value),
      atLeastOneNumber: atLeastOneNumber.test(value),
      atLeastOneSpecialCharacter: atLeastOneSpecialCharacter.test(value),
    });
  };

  const [confirmPasswordValidations, setConfirmPasswordValidations] = useState({
    atLeast8Characters: false,
    atLeastOneLowerCase: false,
    atLeastOneUpperCase: false,
    atLeastOneNumber: false,
    atLeastOneSpecialCharacter: false,
  });

  const checkConfirmPasswordValidations = (value) => {
    return setConfirmPasswordValidations({
      atLeast8Characters: value.length >= 8,
      atLeastOneLowerCase: atLeastOneLowerCase.test(value),
      atLeastOneUpperCase: atLeastOneCapitalCase.test(value),
      atLeastOneNumber: atLeastOneNumber.test(value),
      atLeastOneSpecialCharacter: atLeastOneSpecialCharacter.test(value),
    });
  };

  const [isError, setIsError] = useState({});
  const handleChange = (name) => (event) => {
    const stringValidatorValues = Object.values(passwordValidations);

    if (!stringValidatorValues.every((element) => element === true)) {
      errors.password = "Password must match all requirements";
    } else {
    }
    setData({ ...data, [name]: event.target.value });
  };

  let errors = {
    email: false,
    password: false,
    confirmPassword: false,
  };

  const UserSignUp = async () => {
    const res = await api("post", constants.endPoints.UserSignUp, data);
    if (res.success) {
      setShowUserProfile(res.data);
      setShowEmail(res.data);

      const email = res.data.email;
      sessionStorage.setItem("email", email);

      setShowVerificationPage(true);
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

  const handleSubmit = async () => {
    let formErrors = await passwordValidator(
      data,
      passwordValidations,
      confirmPasswordValidations
    );
    setIsError({ ...formErrors });
    if (!formErrors) UserSignUp();
  };
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, [navigate]);

  // Google Login

  const googleLogin = async (decoded) => {
    const userData = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${decoded.access_token}`,
        },
      }
    );
    console.log("userData", userData);
    const res = await api(
      "post",
      constants.endPoints.socialSignUp,
      userData.data
    );
    if (res.success) {
      console.log("res", res);
      const email = res.data.email;
      const token = JSON.stringify(res.data.token);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("token", token);
      navigate("/dashboard");
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };
  const googleLoginFun = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.log("credentialResponse", credentialResponse);
      // const decoded = jwtDecode(credentialResponse.credential);
      googleLogin(credentialResponse);
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  return (
    <>
      {showVerificationPage ? (
        <VerificationCode
          showEmail={showEmail}
          UserSignUp={UserSignUp}
          setShowVerificationPage={setShowVerificationPage}
        />
      ) : (
        <div className=" ">
          <div className="grid p-0 m-0">
            <div className="md:col-6 bgsign-image">
              <div className="flex flex-column align-items-center px-4 lg:px-0 w-12 lg:w-9 ">
                <div className="">
                  <h3 className="lg:text-6xl text-30 text-primary font-medium lg:line-height-3 m-0">
                    Exchange the Crypto in Mintutes
                  </h3>
                  <p className="text-sm md:text-base lg:py-2 w-12 lg:w-9">
                    Welcome to TINBOX (Tinbox Transfers Ltd.), where trust meets
                    efficiency in the world of currency exchange. Born out of a
                    deep understanding of P2P platforms, we've evolved beyond
                    being active merchants to become your trusted ally in the
                    realm of stablecoin transactions. No more alerts from your
                    financial institution of fraud concerns.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:col-5 col-12 p-0 lg:mt-8 mb-8 m-auto flex flex-column justify-content-center  ">
              <div className="surface-500 border-round-2xl lg:px-6  px-4     ">
                <div className="text-center pt-6 pb-3">
                  <small style={{ width: "60px", height: "27px" }}>
                    <Logo />
                  </small>
                </div>
                <h3 className="lg:text-3xl text-26 font-medium text-primary my-2">
                  Create Account
                </h3>
                <span className="text-gray-300 text-sm">
                  Sign up now and unlock exclusive access!
                </span>
                <div className="mt-3">
                  <CustomInput
                    onChange={handleChange("email")}
                    placeholder="Email"
                    label="Email"
                    required
                    icon={<EmailIcon />}
                  ></CustomInput>
                  {isError?.email && (
                    <p className="text-red-600 text-xs mt-1">
                      {isError?.email}
                    </p>
                  )}
                </div>
                <small>
                  <span className="text-red-500">Please note:</span> This email
                  address should match the one used for e-transfers and must not
                  be altered.
                </small>
                <div className="my-2">
                  <PasswordInputWithStrength
                    type="password"
                    onChange={handleChange("password")}
                    placeholder="Password"
                    value={data.password}
                    required
                    icon="pi-eye"
                    label="Password"
                    passwordValidations={passwordValidations}
                    checkPasswordValidations={checkPasswordValidations}
                  ></PasswordInputWithStrength>
                  {isError?.password && (
                    <p className="text-red-600 text-xs mt-1">
                      {isError?.password}
                    </p>
                  )}
                </div>

                <div className="my-3">
                  <PasswordInputWithStrength
                    type="password"
                    onChange={handleChange("confirmPassword")}
                    placeholder="Enter Confirm Password"
                    value={data.confirmPassword}
                    required
                    icon="pi-eye"
                    label="Confirm Password"
                    passwordValidations={confirmPasswordValidations}
                    checkPasswordValidations={checkConfirmPasswordValidations}
                  ></PasswordInputWithStrength>
                  {isError?.confirmPassword && (
                    <p className="text-red-600 text-xs mt-1">
                      {isError?.confirmPassword}
                    </p>
                  )}
                </div>

                <div className="col-12 my-3  mx-0 px-0">
                  <ButtonComponent
                    onClick={handleSubmit}
                    size=""
                    label="Create account"
                    className="col-12  "
                  ></ButtonComponent>
                </div>
                {/* <div className="p-3 text-center border-1 border-gray-300 border-round-lg ">
                  <span>
                    <i className="pi pi-apple mx-2 text-xl"></i>
                  </span>
                  Continue with Apple ID
                </div> */}
                <div
                  onClick={() => googleLoginFun()}
                  className="p-3 cursor-pointer  flex align-items-center justify-content-center border-1 border-gray-300 border-round-lg my-2"
                >
                  <div className="mx-2 flex align-items-center justify-content-center ">
                    <GoogleIcon />
                  </div>
                  Continue with Google
                </div>

                <div className="text-sm my-5 text-center">
                  <span className="text-white  ">Already have an account?</span>
                  <Link
                    to={"/sign-in"}
                    className="text-primary font-semibold mx-2"
                  >
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
