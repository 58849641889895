const constants = {
  // base_url: "https://tinboxbe.appdeft.in/api/",
  // host: "https://tinboxbe.appdeft.in",

  base_url: "https://api.tinbox.app/api/",
  host: "https://api.tinbox.app",

  // base_url: "http://localhost:1107/api/",
  // host: "http://localhost:1107",


  adminEmail: "cw@tinbox.app",

  personaTemplateId: "itmpl_BVLavzyGU4iknfE4RUXkQX9j96hN",
  personaEnvironmentId: "env_3dJ8zimZAmtLKFNZFeFqNyDW",  // prod //
  // personaEnvironmentId: "env_sj8w6qCqV7QgkT7N1VJSdbH6", // sandbox //
  url: "",
  endPoints: {
    GoogleLogin: "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",
    UserSignUp: "auth/sign-up",
    Otp: "auth/verify-otp",
    Login: "auth/login",
    ForgotPassword: "auth/forgot-password",
    ResetPassword: "auth/reset-password",
    kycVerfication: "auth/kyc-verification",
    checkKYCVerification: "auth/check-kyc-verification",
    createTransaction: "transactions/create",
    getuserTransactions: "transactions/user-transactions",
    getPrice: "auth/get-price",
    getNotifications: "notifications",
    markNotificationAsRead: "notifications/mark-as-read/",
    users: "users/user/",
    updateUser: "users/edit-user",
    uploadFile: "upload",
    contactUs: "users/contact-us",
    socialSignUp: "auth/social-sign-up",
    gasFees: "gas-fees/",
    validateWalletAddress: "auth/validate-wallet-address",
    checkPasswordLock: "auth/check-password-lock",
    adminDetails: "users/admin-details"
  },

  networkOptions: [{ name: "Tron", value: "tron" }, { name: "Ethereum", value: "ethereum" }, { name: "Polygon", value: "polygon" }],
  cryptoCurrencies: ["USDT", "USDC", "CAD", "USD"],
};

export default constants;
